<template>
  <v-switch
    v-model="value"
    inset
    class="mt-0 mb-2 mb-sm-0 mr-n3 mr-sm-0 ml-4 ml-sm-0 no-input-details pa-0"
    :data-cy="`bundle-switcher-${bundle.id}`"
    @change="setBundleSelection"
  />
</template>

<script>
import { isEqual } from 'lodash/fp'

export default {
  props: {
    bundle: {
      type: Object,
      required: true
    },
    selectedPluginIds: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      value: false
    }
  },
  computed: {
    bundlePluginIds () {
      return this.bundle.plugins.map(p => p.easy_price_book_product_id)
    }
  },
  watch: {
    selectedPluginIds (val, oldVal) {
      if (!isEqual(val, oldVal)) {
        this.value = this.isBundleChecked()
      }
    }
  },
  mounted () {
    if (this.isBundleChecked()) {
      this.value = true
    }
  },
  methods: {
    isBundleChecked () {
      return this.bundlePluginIds.every(v => this.selectedPluginIds.includes(v))
    },
    setBundleSelection (checked) {
      this.$emit('bundle-change', { bundle: this.bundle, checked })
    }
  }
}
</script>
